import logo from './logo.svg';
import "../node_modules/bootstrap/dist/css/bootstrap.min.css";
import './App.css';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Header from './Components/Header';
import Home from './Pages/Home';
import AboutUs from './Pages/AboutUs';
import Gallery from './Pages/Gallery';
import Service from './Components/Service';
import GalleryData from "./Assert/GalleryData";
import ContactUs from './Components/ContactUs';

function App() {
  return (
    <>
    <BrowserRouter>
      <Header />
      <Routes>
        <Route path='/' element={<Home />} />
        <Route path='/best-food-catering-navi-mumbai' element={<AboutUs />} />
        <Route path='/pure-veg-caterer-panvel' element={<Gallery />} />
        <Route path='/:slug' element={<Service />} />
        <Route path='/event-organiser-panvel' element={<ContactUs />} />
      </Routes>
    </BrowserRouter>

    </>
  );
}

export default App;
