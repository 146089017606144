import React from 'react'
import "../Css/Breadcrumbs.css";
import { MdKeyboardDoubleArrowRight } from 'react-icons/md';
import commanBanner from "../Assert/commanBanner.avif";

const Breadcrumbs = ({page,title}) => {
    return (
        <>
            <section className='breadcrumbs-sec' style={{background:"linear-gradient(to bottom, rgb(0, 0, 0, 0.3), rgb(0, 0, 0, 0.3)), url('"+commanBanner+"')"}}>
                <div className='breadcrumbs-main-div'>
                    <h1>{title}</h1>
                    <div className='breadcrumbs-subtitle'>
                        <label>
                            Home
                        </label>
                        <label> <MdKeyboardDoubleArrowRight />
                        </label>
                        <label>
                           {page}
                        </label>
                    </div>

                </div>
            </section>

        </>
    )
}

export default Breadcrumbs