import React from 'react'
import { GiChickenOven, GiGlassCelebration } from "react-icons/gi";

import "../Css/WhyChooseUs.css";
import { BsCupHotFill } from 'react-icons/bs';
import { BiSolidDish } from 'react-icons/bi';
import { FaUserAlt } from 'react-icons/fa';
import { RiCustomerService2Line } from 'react-icons/ri';

const WhyChooseUs = () => {
    return (
        <>
            <section className='why-choose-us-sec'>
                <div className='container'>
                    <div className='home-why-choose-us text-center'>
                        <div className='line-why-choose-us text-center'>
                            <div></div>
                            <div className='text-start'>
                                <h4 className='home-why-choose-us-title'> Why Choose Us</h4>
                                <span className='text-muted'> Unmatched quality, personalized service, and attention to detail.
                                </span>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-lg-4 col-md-6'>
                            <div className='d-flex why-choose-us-flex'>
                                <span>
                                    <GiGlassCelebration className='why-choose-us-icon' />
                                </span>
                                <span className='why-choose-us-icon-text'>
                                    <label className='why-choose-us-icon-title'>
                                        Exclusive Design
                                    </label>
                                    <label className='why-choose-us-icon-para'>
                                        Exclusive Design refers to unique and tailored design solutions that cater specifically to individual client needs, ensuring originality and distinction in every project.
                                    </label>
                                </span>
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-6'>
                            <div className='d-flex why-choose-us-flex'>
                                <span>
                                    <BsCupHotFill className='why-choose-us-icon' />

                                </span>
                                <span className='why-choose-us-icon-text'>
                                    <label className='why-choose-us-icon-title'>
                                        Client Focused
                                    </label>
                                    <label className='why-choose-us-icon-para'>
                                        Being client-focused means prioritizing the needs, preferences, and satisfaction of clients, ensuring that every interaction and service is designed to meet their unique requirements and exceed their expectations.
                                    </label>
                                </span>
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-6'>
                            <div className='d-flex why-choose-us-flex'>
                                <span>
                                    <BiSolidDish className='why-choose-us-icon' />
                                </span>
                                <span className='why-choose-us-icon-text'>
                                    <label className='why-choose-us-icon-title'>
                                        Fresh Ingredients

                                    </label>
                                    <label className='why-choose-us-icon-para'>
                                        Fresh ingredients are high-quality, recently harvested foods that enhance the flavour and nutritional value of meals.                                    </label>
                                </span>
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-6'>
                            <div className='d-flex why-choose-us-flex'>
                                <span>
                                    <FaUserAlt className='why-choose-us-icon' />
                                </span>
                                <span className='why-choose-us-icon-text'>
                                    <label className='why-choose-us-icon-title'>
                                        Diverse Client Base
                                    </label>
                                    <label className='why-choose-us-icon-para'>
                                        A diverse client base encompasses a wide range of customers from different backgrounds, industries, and demographics, leading to richer perspectives and innovative solutions.                                    </label>
                                </span>
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-6'>
                            <div className='d-flex why-choose-us-flex'>
                                <span>
                                    <GiChickenOven className='why-choose-us-icon' />

                                </span>
                                <span className='why-choose-us-icon-text'>
                                    <label className='why-choose-us-icon-title'>
                                        Responsible Sourcing
                                    </label>
                                    <label className='why-choose-us-icon-para'>
                                        Responsible sourcing involves ensuring that products are acquired in a sustainable, ethical, and environmentally friendly manner, prioritizing fair labour practices, reducing environmental impact, and supporting local communities.                                    </label>
                                </span>
                            </div>
                        </div>
                        <div className='col-lg-4 col-md-6'>
                            <div className='d-flex why-choose-us-flex'>
                                <span>
                                    <RiCustomerService2Line className='why-choose-us-icon' />

                                </span>
                                <span className='why-choose-us-icon-text'>
                                    <label className='why-choose-us-icon-title'>
                                        Flexible Services
                                    </label>
                                    <label className='why-choose-us-icon-para'>
                                    Flexible services adapt to the varying needs and schedules of clients, providing customized solutions and convenience.
                                    </label>
                                </span>
                            </div>
                        </div>

                    </div>
                    {/* <div className='row'>
                        <div className='col-md-4'>
                            <div className='d-flex why-choose-us-flex'>
                                <span>
                                    <FaUserAlt className='why-choose-us-icon' />
                                </span>
                                <span className='why-choose-us-icon-text'>
                                    <label className='why-choose-us-icon-title'>
                                        Diverse Client Base
                                    </label>
                                    <label className='why-choose-us-icon-para'>
                                        Lorem ipsum is a placeholder text commonly used to demonstrate the visual
                                    </label>
                                </span>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='d-flex why-choose-us-flex'>
                                <span>
                                    <GiChickenOven className='why-choose-us-icon' />

                                </span>
                                <span className='why-choose-us-icon-text'>
                                    <label className='why-choose-us-icon-title'>
                                        Responsible Sourcing
                                    </label>
                                    <label className='why-choose-us-icon-para'>
                                        Lorem ipsum is a placeholder text commonly used to demonstrate the visual
                                    </label>
                                </span>
                            </div>
                        </div>
                        <div className='col-md-4'>
                            <div className='d-flex why-choose-us-flex'>
                                <span>
                                    <RiCustomerService2Line className='why-choose-us-icon' />

                                </span>
                                <span className='why-choose-us-icon-text'>
                                    <label className='why-choose-us-icon-title'>
                                        Flexible Services
                                    </label>
                                    <label className='why-choose-us-icon-para'>
                                        Lorem ipsum is a placeholder text commonly used to demonstrate the visual
                                    </label>
                                </span>
                            </div>
                        </div>
                    </div> */}
                </div>
            </section>
        </>
    )
}

export default WhyChooseUs