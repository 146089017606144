import React from 'react'
import "../Css/Welcome.css";

import welcomeVenue from "../Assert/images/welcomeVenue.avif";
import eventWelcome from "../Assert/images/eventWelcome.avif";
import cateringWelcome from "../Assert/images/cateringWelcome.avif";

const Welcome = () => {
    return (
        <>
            <section>
                <div className='container'>
                    <h3 className='welcome-title'>
                        Sadguru Caterers has earned a phenomenal reputation through its enduring journey of 20 years in the field of catering and event arrangements. As one of the top catering services in Navi Mumbai, we are renowned for our unwavering commitment to “Excellence in Service.” Our passion for innovation and culinary excellence drives us to deliver the best from our kitchen. Upholding the golden verse “Atithi Devo Bhava,” we dedicate ourselves to delighting our clients and their guests with mouth-watering, delicious recipes.

                    </h3>
                    <p className='d-none'>
                        Our distinction as one of the top catering services in Navi Mumbai extends beyond just providing excellent food. We offer a comprehensive service portfolio designed to meet all event needs, whether big or small, personal or official. Our services ensure that every aspect of your event is meticulously planned and executed, allowing you to focus on enjoying the occasion.
                    </p>
                    <div className='row'>
                        <div className='col-lg-6 mb-2'>
                            <div className='welcome-div-start relative'>
                                <img className='welcome-img' src={welcomeVenue} alt='' />
                                <h5 className='welcome-img-tilte'>
                                    Venues
                                </h5>
                            </div>
                        </div>
                        <div className='col-lg-6 mb-2'>
                            <div className='welcome-div-start relative'>
                                <img className='welcome-img' src={eventWelcome} alt='' />
                                <h5 className='welcome-img-tilte'>
                                    Event
                                </h5>
                            </div>

                        </div>
                    </div>
                    <div className='row '>
                        <div className='col-md-12 mb-2 d-flex justify-content-center align-items-center'>
                            <div className='welcome-div-start relative'>
                            <img className='welcome-img' src={cateringWelcome} alt='' />
                                <h5 className='welcome-img-tilte'>
                                    Catering
                                </h5>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}

export default Welcome