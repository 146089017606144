
import Catering1 from "../Assert/galleryImages/galleryCatering.avif";
import Catering2 from "../Assert/galleryImages/galleryCatering(1).avif";
import Catering3 from "../Assert/galleryImages/galleryCatering(2).avif";
import Catering4 from "../Assert/galleryImages/galleryCatering(3).avif";
import Catering5 from "../Assert/galleryImages/galleryCatering(4).avif";
import Catering6 from "../Assert/galleryImages/galleryCatering(5).avif";
import Catering7 from "../Assert/galleryImages/galleryCatering(6).avif";
import Catering8 from "../Assert/galleryImages/galleryCatering(7).avif";
import Catering9 from "../Assert/galleryImages/galleryCatering(8).avif";
import Catering10 from "../Assert/galleryImages/galleryCatering(9).avif";

import venue1 from "../Assert/galleryImages/galleryVenue(1).avif";
import venue2 from "../Assert/galleryImages/galleryVenue(2).avif";
import venue3 from "../Assert/galleryImages/galleryVenue(3).avif";
import venue4 from "../Assert/galleryImages/galleryVenue(4).avif";
import venue5 from "../Assert/galleryImages/galleryVenue(5).avif";
import venue6 from "../Assert/galleryImages/galleryVenue(6).avif";
import venue7 from "../Assert/galleryImages/galleryVenue(7).avif";
import venue8 from "../Assert/galleryImages/galleryVenue(8).avif";
import venue9 from "../Assert/galleryImages/galleryVenue(9).avif";

import event1 from "../Assert/galleryImages/galleryEvent(1).avif";
import event2 from "../Assert/galleryImages/galleryEvent(2).avif";
import event3 from "../Assert/galleryImages/galleryEvent(3).avif";
import event4 from "../Assert/galleryImages/galleryEvent(4).avif";
import event5 from "../Assert/galleryImages/galleryEvent(5).avif";
import event6 from "../Assert/galleryImages/galleryEvent(6).avif";
import event7 from "../Assert/galleryImages/galleryEvent(7).avif";
import event8 from "../Assert/galleryImages/galleryEvent(8).avif";
import event9 from "../Assert/galleryImages/galleryEvent(9).avif";
import event10 from "../Assert/galleryImages/galleryEvent(10).avif";
import event11 from "../Assert/galleryImages/galleryEvent(11).avif";


const galleryData = [

  {
    id: 1,
    img: Catering1,
    alts: "coconut",
    category: "Catering",
  },
  {
    id: 1,
    img: Catering2,
    alts: "coconut",
    category: "Catering",
  },
  {
    id: 1,
    img: Catering3,
    alts: "coconut",
    category: "Catering",
  },
  {
    id: 1,
    img: Catering4,
    alts: "coconut",
    category: "Catering",
  },
  {
    id: 1,
    img: Catering5,
    alts: "coconut",
    category: "Catering",
  },
  {
    id: 1,
    img: Catering6,
    alts: "coconut",
    category: "Catering",
  },
  {
    id: 1,
    img: Catering7,
    alts: "coconut",
    category: "Catering",
  },
  {
    id: 1,
    img: Catering8,
    alts: "coconut",
    category: "Catering",
  },
  {
    id: 1,
    img: Catering9,
    alts: "coconut",
    category: "Catering",
  },
  {
    id: 1,
    img: Catering10,
    alts: "coconut",
    category: "Catering",
  },
  // {
  //   id: 1,
  //   img: Catering1,
  //   alts: "coconut",
  //   category: "Catering",
  // },
  // venue
  {
    id: 1,
    img: venue1,
    alts: "coconut",
    category: "Venues",
  },
  // {
  //   id: 1,
  //   img: "https://webweb.ams3.cdn.digitaloceanspaces.com/data/sadgurucaterers.webweb.ai.in/1669269833_71e496015d4f82ab8c0d.jpg",
  //   alts: "coconut",
  //   category:"Venues",
  // },
  {
    id: 1,
    img: venue2,
    alts: "coconut",
    category: "Venues",
  },
  {
    id: 1,
    img: venue3,
    alts: "coconut",
    category: "Venues",
  },
  {
    id: 1,
    img: venue4,
    alts: "coconut",
    category: "Venues",
  },
  {
    id: 1,
    img: venue5,
    alts: "coconut",
    category: "Venues",
  },
  {
    id: 1,
    img: venue6,
    alts: "coconut",
    category: "Venues",
  },
  {
    id: 1,
    img: venue7,
    alts: "coconut",
    category: "Venues",
  },
  {
    id: 1,
    img: venue8,
    alts: "coconut",
    category: "Venues",
  },
  {
    id: 1,
    img: venue9,
    alts: "coconut",
    category: "Venues",
  },
  // {
  //   id: 1,
  //   img: {venue10},
  //   alts: "coconut",
  //   category: "Venues",
  // },
  // {
  //   id: 1,
  //   img: {venue},
  //   alts: "coconut",
  //   category: "Venues",
  // },
  // events
  {
    id: 1,
    img: event1,
    alts: "coconut",
    category: "Events",
  },
  {
    id: 1,
    img: event2,
    alts: "coconut",
    category: "Events",
  },
  {
    id: 1,
    img: event3,
    alts: "coconut",
    category: "Events",
  },
  {
    id: 1,
    img: event4,
    alts: "coconut",
    category: "Events",
  },
  {
    id: 1,
    img: event5,
    alts: "coconut",
    category: "Events",
  },
  {
    id: 1,
    img: event6,
    alts: "coconut",
    category: "Events",
  },
  {
    id: 1,
    img: event7,
    alts: "coconut",
    category: "Events",
  },
  {
    id: 1,
    img: event8,
    alts: "coconut",
    category: "Events",
  },
  {
    id: 1,
    img: event9,
    alts: "coconut",
    category: "Events",
  },
  {
    id: 1,
    img: event10,
    alts: "coconut",
    category: "Events",
  },
  {
    id: 1,
    img: event11,
    alts: "coconut",
    category: "Events",
  },

]

export default galleryData