import serviceEvents from "../Assert/serviceEvents (1).avif";
import serviesCatering from "../Assert/serviesCatering.avif";
import servicebanquent from "../Assert/servicebanquent.avif";


const serviceData = [
    {
        name:"Events",
        slug:"/event-management-navi-mumbai",
        seoTitle:"Event Management In Navi Mumbai",
        seoDescription:"Discover top-tier event management in Navi Mumbai with Sree Sadguru, offering unparalleled expertise and seamless execution for every occasion.",
        desc :"Event management in Navi Mumbai encompasses a vibrant and dynamic industry that orchestrates a myriad of gatherings, from corporate conferences to cultural festivals and lavish weddings. Navi Mumbai, with its strategic location and burgeoning infrastructure, has become a hub for event organizers seeking to host memorable experiences in a rapidly growing urban landscape.  Event management in Navi Mumbai thrives on meticulous planning, seamless execution, and a deep understanding of client needs. Professionals in this field leverage their expertise to transform ideas into reality, ensuring every detail is meticulously curated to perfection. Whether it's coordinating logistics, managing vendors, or crafting engaging experiences, event managers in Navi Mumbai play a pivotal role in delivering successful events.The demand for event management in Navi Mumbai spans across various sectors. Corporate entities rely on these services to conduct impactful conferences and product launches that leave a lasting impression on stakeholders. With state-of-the-art venues like the CIDCO Exhibition Centre and The Park, Navi Mumbai offers a plethora of options for hosting large-scale corporate events with ease and sophistication.",
        seoNone:"Cultural events also find a vibrant home in Navi Mumbai, where festivals celebrating art, music, and local traditions draw crowds from across the region. Event managers adeptly navigate the nuances of cultural sensitivities and logistical challenges to ensure these events resonate deeply with attendees.Weddings, a cornerstone of Indian culture, are another domain where events shine. From opulent ceremonies at venues like The Acres Club to intimate gatherings overlooking the waterfront, every wedding is a meticulously choreographed affair that reflects the couple's unique style and vision. In essence, embodies creativity, precision, and professionalism. It combines strategic planning with innovative solutions to create unforgettable experiences that captivate audiences and exceed client expectations. As Navi Mumbai continues to evolve as a preferred destination for events, the industry's role in shaping memorable experiences only grows stronger, catering to diverse needs with expertise and flair.",
        img: serviceEvents,
        AllServices: [
            {
                serviceName:"Baby Shower",
                serviceDesc: "Celebrate the upcoming arrival with joy and anticipation at our charming baby shower venue. From delightful decorations to delicious treats, we create a memorable experience filled with love and laughter for you and your loved ones."
    
            },
            {
                serviceName:"Naming Ceremony",
                serviceDesc: "Celebrate the cherished tradition of naming with elegance and warmth at our venue. From personalized decorations to heartfelt ceremonies, we ensure a memorable occasion where family and friends come together to honor and welcome your new bundle of joy."
    
            },
            {
                serviceName:"Thread Ceremony (Munj)",
                serviceDesc: "Celebrate the auspicious thread ceremony (Munj) with tradition and grace at our venue. From intricate rituals to festive decorations, we create a memorable event where family bonds are honored and blessings bestowed upon the young initiate."
    
            },
            {
                serviceName:"Birthday Celebration",
                serviceDesc: "Celebrate another wonderful year with joy and festivities at our venue. From themed decorations to delicious treats, we create a memorable birthday celebration where laughter and happiness abound, making your special day truly unforgettable."
    
            },
            {
                serviceName:"Wedding Celebrations",
                serviceDesc: "Experience the magic of love and commitment at our exquisite venue. With elegant decor, impeccable service, and exquisite cuisine, we craft unforgettable wedding celebrations that capture the essence of romance and create cherished memories for you and your guests."
    
            },
            {
                serviceName:"Corporate Events",
                serviceDesc: "Elevate your corporate gatherings with our versatile venue and impeccable service. Whether hosting meetings, conferences, or team-building events, we provide tailored solutions with sophisticated amenities and culinary excellence, ensuring successful and memorable corporate events every time."
    
            },
            {
                serviceName:"Buffet/Pangat",
                serviceDesc: "Indulge in a delightful buffet experience at Pangat, where flavors blend seamlessly with variety. From traditional dishes to modern cuisines, our buffet offers a feast for all palates, ensuring a satisfying dining experience for every guest."
    
            },
            {
                serviceName:"Diwali Faral",
                serviceDesc: "Celebrate Diwali with our exquisite Faral offerings, featuring a delightful array of traditional sweets and savory treats. From crunchy chaklis to luscious ladoos, our Diwali Faral promises to add joy and sweetness to your festive celebrations."
    
            },
            {
                serviceName:"Packaged Meal",
                serviceDesc: "Here's a suggestion: Convenient, delicious, and ready-to-eat, our packaged meals offer a perfect blend of flavor and nutrition, ideal for busy lifestyles. Discover a variety of options designed to satisfy your taste buds effortlessly."
    
            },

        ]
    },
    {
        name:"Venue",
        slug:"/best-event-planner-navi-mumbai",
        seoTitle:"Best Event Planner In Navi Mumbai",
        seoDescription:"Discover Sree Sadguru, renowned as the best event planner in Navi Mumbai, offering exceptional expertise and memorable experiences.",
        desc :"Sree Sadguru is renowned as the best event planner in Navi Mumbai, providing exquisite venues for a variety of occasions. Nestled in the heart of Navi Mumbai, Sree Sadguru boasts a collection of stunning venues that cater to every event need with impeccable service and attention to detail.For those seeking the perfect wedding venue, Sree Sadguru stands out as the best event planner in Navi Mumbai, offering picturesque settings that enhance the beauty of any ceremony. Their venues are designed to accommodate both intimate gatherings and grand celebrations, ensuring a memorable experience for all guests.Corporate events also find a prestigious home at Sree Sadguru. As the best event planner in Navi Mumbai, they understand the importance of professionalism and ambience. Their venues are equipped with modern amenities and customizable spaces, ideal for conferences, seminars, and corporate parties.",
        seoNone:"Moreover, Sree Sadguru's reputation as the best event planner extends to its exceptional hospitality and seamless event management. From initial planning to execution, their team of dedicated professionals ensures that every detail is meticulously handled, allowing hosts to relax and enjoy their event.Families celebrating milestones such as birthdays or anniversaries also find Sree Sadguru's venues to be the perfect choice. Their versatility in accommodating themes and preferences makes them a favourite among locals.In conclusion, Sree Sadguru's venues stand as a testament to their dedication to being the best at event planning. Whether for weddings, corporate functions, or personal celebrations, their commitment to excellence shines through in every aspect of their service. For those seeking unforgettable experiences in Navi Mumbai's event landscape, Sree Sadguru remains the premier choice, offering unparalleled venues and top-tier event planning services.",
        img: servicebanquent,
        AllServices: [
            {
                serviceName:"Best Venue and Menu",
                serviceDesc: "Discover the ultimate venue and menu choices for your special event. Whether it's a wedding, corporate gathering, or casual celebration, we provide top-notch options to make your occasion unforgettable with exceptional ambiance and delectable dishes."
    
            },
            {
                serviceName:"Fantastic place for wedding & other functions",
                serviceDesc: "Spectacular Venue for Weddings and Events: A Perfect Blend of Elegance and Charm, Ensuring Unforgettable Celebrations with Exceptional Service, Scenic Views, and Luxurious Amenities, Creating a Memorable Experience for All Your Special Occasions and Gatherings."
    
            },
            {
                serviceName:"Well located",
                serviceDesc: "Discover an ideal venue perfectly situated for your events, offering exceptional convenience and accessibility to guests, ensuring a memorable experience with top-notch facilities in a prime location designed to meet all your needs seamlessly"
    
            },
            {
                serviceName:"Awesome place",
                serviceDesc: "Discover the Ultimate Venue for Your Events: Experience the Perfect Blend of Elegance and Sophistication at Awesome Place, Where Every Detail is Meticulously Crafted to Ensure an Unforgettable and Seamless Celebration for All Occasions."
    
            },
            {
                serviceName:"Great ambiance",
                serviceDesc: "Discover the Ultimate Venue for Your Events: Experience the Perfect Blend of Elegance and Sophistication at Awesome Place, Where Every Detail is Meticulously Crafted to Ensure an Unforgettable and Seamless Celebration for All Occasions"
    
            },
            {
                serviceName:"Beautiful and amazing decor",
                serviceDesc: "Stunning and Exquisite Decor Venue: A Perfect Blend of Elegance and Charm, Ideal for Unforgettable Celebrations, Corporate Events, and Special Occasions. Experience Impeccable Design and Breathtaking Ambiance in Every Corner of This Extraordinary Space."
    
            },
            {
                serviceName:"Nice AC hall with great service",
                serviceDesc: "Elegant air-conditioned hall offering exceptional service: perfect for weddings, corporate events, and celebrations. Experience premium comfort and outstanding hospitality in a beautifully designed venue, ensuring unforgettable moments for every occasion. Reserve now for a memorable event!"
    
            },
            

        ]
    },
    {
        name:"Catering",
        slug:"/best-caterer-for-wedding-panvel",
        seoTitle:"Best Caterer For Wedding In Panvel",
        seoDescription:"Discover the best caterer for wedding in Panvel at Sree Sadguru, offering exquisite culinary experiences tailored to perfection.",
        desc :"Ensuring a memorable wedding in Panvel hinges on selecting the best caterer, and Sree Sadguru Caterers consistently stands out in this domain. Renowned as the 'Best Caterer for wedding in Panvel,' Sree Sadguru combines expertise, innovation, and a commitment to excellence to offer unparalleled culinary experiences that elevate any wedding celebration. Sree Sadguru's reputation as the Best Caterer for wedding in Panvel is built on their meticulous attention to detail and a diverse menu that caters to every palate. Whether it's traditional Maharashtrian delicacies, exquisite North Indian cuisine, or contemporary fusion dishes, their offerings are designed to delight guests and leave a lasting impression. We serve traditional food like Modak, Puranpoli, Sandan, Panha, Thalipeeth, and more. What truly sets Sree Sadguru apart is their dedication to quality and presentation. Each dish is crafted with the finest ingredients, ensuring both flavor and visual appeal. From starters to desserts, every element of their menu reflects their passion for culinary artistry.",
        img: serviesCatering,
        AllServices: [
            {
                serviceName:"Excellent food quality & Quantity",
                serviceDesc: "Experience unparalleled dining with our catering service, offering exceptional food quality and generous portions that satisfy every palate. Delight in a wide array of culinary delights, perfect for any event, ensuring a memorable and delectable experience."
    
            },
            {
                serviceName:"Delicious and tasty Food",
                serviceDesc: "Discover a world of culinary delight with delicious and tasty food that will tantalize your taste buds, offering a perfect blend of flavors and textures, crafted to satisfy your cravings and leave you wanting more with every bite."
    
            },
            {
                serviceName:"Amazing food",
                serviceDesc: "Delight in an array of exquisite dishes that promise to captivate your senses. Immerse yourself in a culinary experience filled with exceptional flavors and remarkable presentations, ensuring every bite is a journey of pure enjoyment and satisfaction."
    
            },
            {
                serviceName:"Awesome Taste",
                serviceDesc: "Experience the exquisite flavors of Awesome Taste Catering, where every dish is crafted with passion and precision to elevate your event. From intimate gatherings to grand celebrations, savor culinary excellence with us."
    
            },
            {
                serviceName:"On time Delivery food",
                serviceDesc: "Prompt Delivery Food Catering Services: Ensuring Timely Service and Fresh, Delicious Meals"
    
            }, 
            {
                serviceName:"Veg caterers",
                serviceDesc: "Exquisite Veg Caterers Offering Unmatched Culinary Delights for Every Occasion: Perfectly Crafted Menus, Impeccable Service, and Unforgettable Dining Experiences Tailored to Your Event's Unique Needs."
    
            },
            {
                serviceName:"Fresh and Hygienic Cuisine",
                serviceDesc: "Discover a culinary journey with fresh and hygienic cuisine that promises to delight your palate. Indulge in a harmonious mix of flavors and textures, expertly prepared to satisfy your cravings and leave you craving more after every bite."
                
            },
        ]
    },
]

export default serviceData;