import React from 'react'
import "../../Css/MakeARequest.css";
import { Link } from 'react-router-dom';

const MakeARequest = () => {
    
    return (
        <>
            <section className='make-a-request-sec'>
                <div className='make-a-req-main'>

                    <p>
                        UPCOMING EVENTS? BOOK NOW
                    </p>
                    <h4 className='make-a-req-title'> Let Us Make Your Events Flawless</h4>
                    <Link to='/event-organiser-panvel'>

                        <button className='banner-btn'>
                            SEND A REQUEST
                        </button>
                    </Link>

                </div>
            </section>

        </>
    )
}

export default MakeARequest