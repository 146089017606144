import React from 'react'
import "../Css/HomeAboutUs.css";
import { Link } from 'react-router-dom';

const HomeAboutUs = () => {
    return (
        <>
            <section className='home-about-us-sec'>
                <div className='home-about-us-div'>
                    <div className='container text-center home-about-us-container'>
                        <div className='home-about-us-discover-outter text-center'>
                            <div className='line-home-about-us text-center'>
                                <div></div>
                                <div className='text-start'>
                                    <h4 className='home-about-us-title'> About Us</h4>
                                    <span className='text-muted'> DISCOVER OUR STORY </span>
                                </div>
                            </div>


                        </div>

                        <p className='home-about-us-para'>
                        At Sadguru Caterers, we understand that every event is unique and special. This understanding drives us to tailor our services to meet the specific requirements of each client. Our experienced team works closely with you to create a customized menu that reflects your preferences and the nature of your event. From traditional Indian delicacies to contemporary global cuisines, our diverse menu options are crafted to cater to all tastes and dietary needs.
                        Our reputation as one of the top catering services in Navi Mumbai is built on our relentless pursuit of quality and customer satisfaction. We use only the freshest ingredients, sourced from trusted suppliers, to ensure that every dish we serve is of the highest standard. Our dedicated staff is trained to provide impeccable service, ensuring that your event runs smoothly from start to finish.
                        </p>
                        <p className='d-none'>
                        In addition to our culinary expertise, we also offer a range of event management services, including venue selection, décor, entertainment, and more. Our goal is to provide a seamless, stress-free experience for our clients, making their events memorable for all the right reasons. With Sadguru Caterers, you can be assured of an event that is not only delicious but also flawlessly executed.
                        </p>
                        <div className='home-about-us-discover-outter-read text-center'>
                            <div className='line-home-about-us-read text-center'>
                                {/* <h4 className='home-about-us-title'> READ FULL STORY</h4> */}
                                <Link to='/best-food-catering-navi-mumbai'>

                                <span className='home-about-us-read'> READ FULL STORY </span>
                                </Link>
                                <div></div>
                            </div>
                            {/* <span> DISCOVER OUR STORY </span> */}

                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}

export default HomeAboutUs