import React from 'react'
import "../Css/Service.css";
import Accordion from "react-bootstrap/Accordion";
import Breadcrumbs from './Breadcrumbs';
import { Divider } from 'antd';
import Footer from './Footer';
import MakeARequest from './AboutUs/MakeARequest';
import { Link, useParams } from 'react-router-dom';
import ServiceData from "../Assert/ServiceData";
import { MetaTags } from 'react-meta-tags';

const Service = () => {
    const { slug } = useParams()
    const getserviceObj = ServiceData.find((v) => v.slug === "/" + slug)
    console.log(slug);
    console.log(getserviceObj);
    const canonical = window.location.origin + "/" + slug;
    console.log(canonical);

    return (
        <>
            <MetaTags>
                <title>Best Food Catering In Navi Mumbai | Sree Sadguru</title>
                <meta title="Best Food Catering In Navi Mumbai | Sree Sadguru" />
                <meta
                    name="description"
                    content={getserviceObj?.seoDescription}
                />
                <meta
                    name="Top Catering Services In Navi Mumbai,Best Food Catering In Navi Mumbai,Event Management In Navi Mumbai ,Best Event Planner In Navi Mumbai,Best Caterer For Wedding In Panvel, Corporate Event Planner In Navi Mumbai,Pure Veg. Caterer Near Panvel,Event Organiser In Panvel"
                />
                <link rel="canonical" href={canonical} />
                <meta http-equiv="cache-control" content="no-cache" />
                <meta http-equiv="expires" content="0" />
                <meta http-equiv="pragma" content="no-cache" />
                <meta property="og:title" content="Best Food Catering In Navi Mumbai | Sree Sadguru" />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="website" />
                <meta property="og:url" content={canonical} />
                <meta property="og:description" content={getserviceObj?.seoDescription} />
                <meta property="og:image" content="https://www.sadgurucaterers.com//static/media/JMS.7493f202fc387288d7c0.png" />
            </MetaTags>
            <Breadcrumbs title={getserviceObj?.seoTitle} page="Events" />
            <section className='service-sec'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-9'>
                            <div>
                                <h4 className='services-title'> {getserviceObj?.name} </h4>
                                <Divider />
                                <p className='service-para'> {getserviceObj?.desc}    </p>
                                <p className='d-none'> {getserviceObj?.seoNone}    </p>
                                <div className='my-2'>
                                    <img className='service-img' src={getserviceObj.img} alt='' />

                                </div>
                                <h4 className='services-title'> Our Service Including </h4>
                                <Divider />
                            </div>
                            <div>
                                <Accordion>

                                    {getserviceObj.AllServices.map((v, i) => {
                                        return (
                                            <>
                                                <Accordion.Item eventKey={i}>
                                                    <Accordion.Header className="container">
                                                        {" "}
                                                        <h6 className=" accordion-title">{i + 1}. {v.serviceName} </h6>
                                                    </Accordion.Header>
                                                    <Accordion.Body className="container">
                                                        <div>
                                                            <p className='accordion-para'>  {v.serviceDesc}        </p>
                                                        </div>
                                                    </Accordion.Body>
                                                </Accordion.Item>
                                                <hr />
                                            </>
                                        )
                                    })}

                                    {/* <hr /> */}
                                </Accordion>
                            </div>
                        </div>
                        <div className='col-md-3'>
                            <div className='services-right-main'>
                                <h4 className='services-right-title'> Service </h4>
                            </div>
                            <Divider />
                            <ul>
                                <Link to='/event-management-navi-mumbai'>

                                    <li as={Link} to="/event-management-navi-mumbai" className={`${slug === '/event-management-navi-mumbai' ? "list-active" : ""}`} > Event</li>
                                </Link>
                                <Divider />
                                <Link to='/best-event-planner-navi-mumbai'>  <li className={`${slug === 'best-event-planner-navi-mumbai' ? "list-active" : ""}`}> Venue</li> </Link>
                                <Divider />
                                <Link to='/best-caterer-for-wedding-panvel'>  <li className={`${slug === 'best-caterer-for-wedding-panvel' ? "list-active" : ""}`}> Catering</li></Link>
                                <Divider />
                            </ul>
                        </div>

                    </div>
                </div>
            </section>
            <MakeARequest />
            <Footer />

        </>
    )
}

export default Service