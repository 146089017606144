import React from 'react'
import "../../Css/WelcomeAboutUs.css";
import aboutImg from '../../Assert/aboutUs(1).avif'

const WelcomeAboutUs = () => {
    return (
        <>
            <section className='welcome-about-us-sec'>
                <div className='container'>
                    <div className='row'>
                        <div className='col-md-6 order-2 order-md-1'>
                            <div >
                                <h4 className='welcome-about-us-title mb-3'>
                                    Welcome to Sree Sadguru, a premier banquet, catering, and event planning service
                                </h4>
                                <p>
                                    Over the course of its ten-year tenure in the catering and event planning industries, Sadguru Caterers has established an extraordinary reputation. As the best food catering in Navi Mumbai, we have become synonymous with "Excellence in Service." Our unwavering desire to be creative and maximize the potential of the kitchen sets us apart. We work tirelessly to satisfy our clients and their guests' appetites with delectable cuisine, adhering to the golden hymn "Atithi Devo Bhava."
                                    In addition to our stellar reputation for providing the best food, we offer a wide range of services that fulfil all our client's event requirements. From private gatherings to formal events, we accommodate all sizes, ensuring that every occasion is a memorable success. Our commitment to being the best food catering in Navi Mumbai drives us to exceed expectations and deliver outstanding experiences.
                                </p>
                            </div>
                        </div>
                        <div className='col-md-6 order-1 order-md-2'>
                            <img className='welcome-about-us-img' style={{ width: "100%" }} src={aboutImg} />
                        </div>
                    </div>

                </div>
            </section>
        </>
    )
}

export default WelcomeAboutUs