import React from 'react'
import "../../Css/OurStory.css";
import aboutUs2 from "../../Assert/aboutUs(2).avif";

const OurStory = () => {
    return (
        <>
            <section className='our-story-about-us'>
                <div className='container'>
                    <div className='home-why-choose-us text-center'>
                        <div className='line-home-gallery text-center'>
                            <div></div>
                            <div className='text-start'>
                                <h4 className='home-why-choose-us-title'> Our Story</h4>
                                <span className='text-muted'> The Journey Behind Our Success  </span>
                            </div>
                        </div>
                    </div>
                    <div className='row'>
                        <div className='col-md-6 d-flex justify-content-center align-items-center'>
                            <div>
                                <img className='our-story-about-us-img' src={aboutUs2} />
                            </div>
                        </div>
                        <div className='col-md-6'>
                            <div >
                                <h4 className='our-story-about-us-title mb-4'>
                                    The Journey of Excellence: How We Built Our Catering and Event Services
                                </h4>
                                <p className='our-story-about-us-para'>
                                    Sadguru Caterers has established an extraordinary reputation over its ten-year tenure in the catering and event planning industries. As the Best Food Catering in Navi Mumbai, we are renowned for providing "Excellence in Service" at a reasonable price. Our unwavering desire is to be creative and maximize the potential of the kitchen. We work hard to satisfy our clients and their guests' appetites with delectable cuisine, adhering to the golden hymn "Atithi Devo Bhava."
                                    We offer a wide range of services that enable us to provide our clients with comprehensive solutions for all their event needs. Whether it's a private gathering or a formal event, we accommodate all sizes and types, ensuring excellence in every detail. Trust Sadguru Caterers to deliver the best food and make your event unforgettable.

                                </p>
                            </div>
                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}

export default OurStory