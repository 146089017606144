import React from 'react'
import "../Css/Header.css";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import logo from "../Assert/logo.png";
import { Link } from 'react-router-dom';

const Header = () => {
  return (
    <>
       <Navbar expand="lg" className="bg-body-tertiary">
      <Container className='header-main-container'>
      <Navbar.Collapse id="basic-navbar-nav">
          <Nav className="me-auto">
            <Nav.Link as={Link} to="/best-food-catering-navi-mumbai" href="#home">About us</Nav.Link>
            <Nav.Link as={Link} to="/event-management-navi-mumbai" href="#event">Event</Nav.Link>
            <Nav.Link as={Link} to="/best-event-planner-navi-mumbai" href="#venue">Venues</Nav.Link>
            {/* <NavDropdown title="Dropdown" id="basic-nav-dropdown">
              <NavDropdown.Item href="#action/3.1">Action</NavDropdown.Item>
              <NavDropdown.Item href="#action/3.2">
                Another action
              </NavDropdown.Item>
              <NavDropdown.Item href="#action/3.3">Something</NavDropdown.Item>
              <NavDropdown.Divider />
              <NavDropdown.Item href="#action/3.4">
                Separated link
              </NavDropdown.Item>
            </NavDropdown> */}
          </Nav>
        </Navbar.Collapse>
        <Navbar.Brand as={Link} to='/' href="#home">
            <div>
                <img src={logo} className='header-logo' />
            </div>
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse id="basic-" className='collapse-two'>
          <Nav className="">
            <Nav.Link as={Link} to="/best-caterer-for-wedding-panvel" href="#catering">Catering</Nav.Link>
            <Nav.Link as={Link} to="/pure-veg-caterer-panvel" href="#link">Gallery</Nav.Link>
            <Nav.Link as={Link} to="/event-organiser-panvel" href="#contact">Contact us</Nav.Link>
            
          </Nav>
        </Navbar.Collapse>
      </Container>
    </Navbar>  
    </>
  )
}

export default Header