import React, { useRef, useState } from 'react';
import "../Css/HomeSwiper.css";
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';


// import required modules
import { Autoplay, Navigation } from 'swiper/modules';
import { Link } from 'react-router-dom';
import banquent from '../Assert/images/banquentBanner.avif'
import cateringBanner from '../Assert/images/cateringBanner.avif'
import eventBanner from '../Assert/images/eventBanner.avif'

const HomeSwiper = () => {
    return (
        <>
            <Swiper navigation={true} modules={[Autoplay, Navigation]}
                autoplay={{
                    delay: 2500,
                    disableOnInteraction: false,
                }}
                className="mySwiper">
                <SwiperSlide>
                    <div className='relative'>
                        <img className='banner-image' src={cateringBanner} alt='' />
                        <div className='banner-text'>
                            <h2> Catering</h2>
                            <div className='banner-title-para'>
                                <h1> Top Catering Services In Navi Mumbai</h1>
                            </div>
                            <Link to='/best-caterer-for-wedding-panvel'>

                                <button className='banner-btn'>
                                    LERAN MORE
                                </button>
                            </Link>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className='relative'>
                        <img className='banner-image' src={banquent} alt='' />
                        <div className='banner-text'>
                            <h2> Venues</h2>
                            <div className='banner-title-para'> Let's Plan Your Next Celebration at Our Venue Together</div>
                            <Link to='/best-event-planner-navi-mumbai'>
                                <button className='banner-btn'>
                                    LERAN MORE
                                </button>
                            </Link>
                        </div>
                    </div>
                </SwiperSlide>
                <SwiperSlide>
                    <div className='relative'>
                        <img className='banner-image' src={eventBanner} alt='' />
                        <div className='banner-text'>
                            <h2> Event</h2>
                            <div className='banner-title-para'> Let's plan Your next event  Together</div>
                            <Link to='/event-management-navi-mumbai'>
                                <button className='banner-btn'>
                                    LERAN MORE
                                </button>
                            </Link>
                        </div>
                    </div>
                </SwiperSlide>

            </Swiper>
        </>
    )
}

export default HomeSwiper