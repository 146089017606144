import React from 'react'

import { Swiper, SwiperSlide } from 'swiper/react';
import { Pagination } from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/pagination';
import 'swiper/css/navigation';
import { Autoplay ,Navigation } from 'swiper/modules';
import { RiDoubleQuotesL } from "react-icons/ri";

import galleryData from "../Assert/GalleryData";
import {
    DownloadOutlined,
    RotateLeftOutlined,
    RotateRightOutlined,
    SwapOutlined,
    ZoomInOutlined,
    ZoomOutOutlined,
} from '@ant-design/icons';
import { Image, Space } from 'antd';

import "../Css/HomeGallery.css";

const HomeGallery = () => {
    return (
        <>
            <section className='home-gallery-section'>
                <div className='container'>
                    <div className='home-why-choose-us text-center'>
                        <div className='line-home-gallery text-center'>
                            <div></div>
                            <div className='text-start'>
                                <h4 className='home-why-choose-us-title'> Gallery</h4>
                                <span className='text-muted'> Explore our exceptional work and see what sets us apart.  </span>
                            </div>
                        </div>
                    </div>
                </div>
                <Image.PreviewGroup>
                    <Swiper
                        slidesPerView={1}
                        spaceBetween={10}
                        // pagination={{
                        //     clickable: true,
                        // }}
                        autoplay={{
                            delay: 2500,
                            disableOnInteraction: false,
                        }}
                        breakpoints={{
                            640: {
                                slidesPerView: 2,
                                spaceBetween: 20,
                            },
                            768: {
                                slidesPerView: 3,
                                spaceBetween: 40,
                            },
                            1024: {
                                slidesPerView: 4,
                                spaceBetween: 50,
                            },
                        }}
                        navigation={true} modules={[Autoplay ,Navigation]}
                        className="mySwiper"
                    >
                        {/* {serviceData.map((v,i)=>{ */}
                        {galleryData.map((v, i) => {
                            return (
                                <SwiperSlide>

                                    <>
                                        <Image
                                            height={350}
                                            src={v.img}
                                        />
                                    </>

                                </SwiperSlide>
                            )
                        })}


                    </Swiper>
                </Image.PreviewGroup>
            </section>
        </>
    )
}

export default HomeGallery