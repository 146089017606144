import React, { useState } from 'react'
import "../Css/ContactUs.css";
import Breadcrumbs from './Breadcrumbs';
import { IoMdCall } from "react-icons/io";
import { FaSquareInstagram } from "react-icons/fa6";
import { FaTwitter } from "react-icons/fa";
import { FaFacebook } from "react-icons/fa";
import { GrInstagram } from "react-icons/gr";
import { FaLinkedin } from "react-icons/fa6";
import { Slide } from "react-reveal";
import { FaMapLocation } from "react-icons/fa6";
import { MdEmail } from "react-icons/md";
import Footer from './Footer';
import { MetaTags } from 'react-meta-tags';
import axios from 'axios';
import $ from "jquery";

const ContactUs = () => {


    const [errorField, setErrorField] = useState(false)
    const [sucessMess, setSucessMess] = useState(false)
    const [formData, setFormData] = useState({
        fname: '',
        email: '',
        contact: '',
        mess: '',
        action: 'contact',

    });
    const handleChange = (e) => {
        const { name, value } = e.target;
        setFormData(prevState => ({
            ...prevState,
            [name]: value
        }));
    };
    const handleCreate = async (e) => {
        e.preventDefault();
        if (formData.fname &&  formData.email && formData.contact && formData.mess) {
            // setErrorField(true);
            try {
                const formDataToSend = new FormData();
                formDataToSend.append('fname', formData.fname);
                formDataToSend.append('email', formData.email);
                formDataToSend.append('mess', formData.mess);
                formDataToSend.append('contact', formData.contact);
                formDataToSend.append('action', formData.action);
        
                var body =
                    '<!DOCTYPE html><html><head><title>Enquiry Lead</title></head><body><div style="font-family:Arial,sans-serif;max-width:600px;margin:0 auto;background-color:#f2f2f2;padding:20px"><h2 style="color:#dc9d7e">Sadguru Caterers</h2><p>Hello</p><p>Thank you for your interest in our products/services.</p><p>Please check your enquiry which generated from website:</p><table cellpadding="5" style="margin:0"><tr><td style="text-align:left;color:#dc9d7e"><strong>Name:</strong></td><td style="text-align:left">' +
                    formData.fname +
                    '</td></tr><tr><td style="text-align:left;color:#dc9d7e"><strong>Email:</strong></td><td style="text-align:left">' +
                    formData.email +
                    '</td></tr><tr><td style="text-align:left;color:#dc9d7e"><strong>Message:</strong></td><td style="text-align:left">' +
                    formData.mess +
                    '</td></tr><tr><td style="text-align:left;color:#dc9d7e"><strong>Mobile Number:</strong></td><td style="text-align:left">' +
                    formData.contact +
                    '</td></tr></table><p style="font-weight:700">Best regards,<br>Your Team at Sadguru Caterers</p></div></body></html>';
        
                $.post(
                    "https://skdm.in/server/v1/send_lead_mail.php",
                    {
                        toEmail: "sales@sadgurucatererers.com",
                        fromEmail: "skdmlead@gmail.com",
                        bccMail: "skdmlead@gmail.com",
                        mailSubject: "New Customer Lead",
                        mailBody: body,
                        accountName: "Interior Concepts",
                        accountLeadSource: "interiorconcepts.org",
                        accountLeadName: formData.fname,
                        accountLeadEmail: formData.email,
                        accountLeadPhone: formData.contact,
                    },
                    function (dataa, status) {
                        console.log("data :" + dataa);
                    }
                );
        
                console.log(formData);
                alert("Your Form has Submitted Our team will contact with You soon.");
                setFormData({
                    fname: '',
                    email: '',
                    contact: '',
                    mess: '',
                    action: 'contact',
                });
            } catch (error) {
                console.error('Error submitting data:', error);
            }
            return;
        } else {
            setErrorField(false);
            
        }
        
    };
    
    return (
        <>
            <MetaTags>
                <title>Event Organiser In Panvel | Sree Sadguru</title>
                <meta title="Event Organiser In Panvel | Sree Sadguru" />
                <meta
                    name="description"
                    content="Sree Sadguru, your trusted event organizer in Panvel, specializing in memorable gatherings tailored to your needs."
                />
                <meta
                    name="Top Catering Services In Navi Mumbai,Best Food Catering In Navi Mumbai,Event Management In Navi Mumbai ,Best Event Planner In Navi Mumbai,Best Caterer For Wedding In Panvel, Corporate Event Planner In Navi Mumbai,Pure Veg. Caterer Near Panvel,Event Organiser In Panvel"
                />
                <link rel="canonical" href="https://www.sadgurucaterers.com/best-food-catering-navi-mumbai" />
                <meta http-equiv="cache-control" content="no-cache" />
                <meta http-equiv="expires" content="0" />
                <meta http-equiv="pragma" content="no-cache" />
                <meta property="og:title" content="Event Organiser In Panvel | Sree Sadguru" />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://www.sadgurucaterers.com/best-food-catering-navi-mumbai" />
                <meta property="og:description" content="Sree Sadguru, your trusted event organizer in Panvel, specializing in memorable gatherings tailored to your needs." />
                <meta property="og:image" content="https://www.sadgurucaterers.com//static/media/JMS.7493f202fc387288d7c0.png" />
            </MetaTags>
            <Breadcrumbs title="Event Organiser In Panvel" page="Contact Us" />
            <section className="sec-contact">
                <div className="container">
                    <div className="row">
                        <Slide left>
                            <div className="col-md-6">
                                <h2 className="contactus-section-title">Let's Talk</h2>
                                <h2 className="service-title">
                                    {" "}
                                    Planning a banquet, event, or catering service? Let's discuss the details..
                                </h2>
                                <div className="pt-5">
                                    <h3>Call Us</h3>
                                    <div className="contact-icon">
                                        <div>
                                            <p className="d-flex">
                                                {" "}
                                                <label className="mr-4">
                                                    <FaMapLocation className="call-icon" />
                                                </label>
                                                <a
                                                    style={{
                                                        textDecoration: "none",
                                                        fontSize: "20px",
                                                        color: "var(--color1)",
                                                        fontWeight: "700",
                                                        marginLeft: "15px",
                                                    }}
                                                    href="tel:+919930387745"
                                                >
                                                    001, Yashoraj Apt, Lokmanya Tilak Road, Manek Nagar, Old Panvel, Panvel, Navi Mumbai, Maharashtra 410206


                                                </a>

                                            </p>
                                            <p>
                                                {" "}

                                            </p>
                                        </div>
                                        <div>
                                            <p className="">
                                                {" "}
                                                <label className="mr-4">
                                                    <IoMdCall className="call-icon" />
                                                </label>
                                                <a
                                                    style={{
                                                        textDecoration: "none",
                                                        fontSize: "20px",
                                                        color: "var(--color1)",
                                                        fontWeight: "700",
                                                        marginLeft: "15px",
                                                    }}
                                                    href="tel:+919323674158"
                                                >
                                                    +91 9323674158
                                                </a>   /
                                                <a
                                                    style={{
                                                        textDecoration: "none",
                                                        fontSize: "20px",
                                                        color: "var(--color1)",
                                                        fontWeight: "700",
                                                        marginLeft: "15px",
                                                    }}
                                                    href="tel:+91 9323674989"
                                                >
                                                    +91 9323674989
                                                </a>{" "}
                                            </p>
                                            <p>
                                                {" "}

                                            </p>
                                        </div>

                                        <div>
                                            <p>
                                                {" "}
                                                <label className="mr-4">
                                                    <MdEmail className="call-icon" />
                                                </label>
                                                <a
                                                    style={{
                                                        textDecoration: "none",
                                                        fontSize: "20px",
                                                        color: "var(--color1)",
                                                        fontWeight: "700",
                                                        marginLeft: "15px",
                                                    }}
                                                    href="sales@sadgurucatererers.com"
                                                >
                                                    sales@sadgurucatererers.com
                                                </a>

                                            </p>
                                            <p>
                                                {" "}

                                            </p>
                                        </div>
                                    </div>
                                    <div className="social-meida-icon">
                                        <div>
                                            <a
                                                href="https://www.facebook.com/"
                                                target="_blank"
                                            >
                                                {" "}
                                                <FaFacebook className="social-app-icon" />
                                            </a>
                                        </div>
                                        {/* <div>
                                            <a href="https://twitter.com/" target="_blank">
                                                <FaTwitter className="social-app-icon" />
                                            </a>
                                        </div> */}
                                        <div>
                                            <a href="https://www.instagram.com/" target="_blank">
                                                <GrInstagram className="social-app-icon" />
                                            </a>
                                        </div>
                                        <div>
                                            <a href="https://www.linkedin.com/company/" target="_blank">
                                                <FaLinkedin className="social-app-icon" />
                                            </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </Slide>
                        <Slide right>
                            <div className="col-md-6">
                                <div className="well">
                                    <form action="contact" method="post" onSubmit={handleCreate}>
                                        <div>
                                            <input
                                                type="text"
                                                placeholder="Name"
                                                name="fname"
                                                value={formData.fname}
                                                onChange={handleChange}
                                                id="fname"
                                                required=""
                                            />
                                            {/* <label htmlFor="login-username">Username</label> */}
                                        </div>
                                        <div>
                                            <input
                                                type="number"
                                                placeholder="Phone Number"
                                                name="contact"
                                                value={formData.contact}
                                                onChange={handleChange}
                                                id="contact"
                                                required=""
                                            />
                                        </div>
                                        <div>
                                            <input
                                                type="email"
                                                placeholder="Email"
                                                name="email"
                                                id="email"
                                                value={formData.email}
                                                onChange={handleChange}
                                                required=""
                                            />
                                            {/* <label htmlFor="login-username">Username</label> */}
                                        </div>
                                        <div>
                                            <textarea
                                                name="mess"
                                                id="message"
                                                value={formData.mess}
                                                onChange={handleChange}
                                                rows="5"
                                                cols="33"
                                                placeholder="type your message here...."
                                            ></textarea>
                                            {/* <label htmlFor="login-username">Username</label> */}
                                        </div>

                                        {/* <a href="#" id="forgot-passwd">
       Forgot Password?
     </a> */}
                                        <div className="">
                                            <button className="button" id="btn-submit">
                                                <span className="button-text">Send</span>
                                                <div className="button-loader">
                                                    <div />
                                                    <div />
                                                    <div />
                                                </div>
                                            </button>
                                        </div>
                                    </form>
                                    {/* <p>
      Don't have an account? <a href="#">Sign Up</a>.
    </p> */}
                                </div>
                            </div>
                        </Slide>
                    </div>
                </div>
            </section>

            <section>
                <div className='container-fluid'>
                    <iframe
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3772.654334254389!2d73.10663717580488!3d18.990867354638507!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3be7e83e4cfa9f53%3A0x5ad20f82adc7ad94!2sShree%20Sadguru%20Caterers%20%26%20Events!5e0!3m2!1sen!2sin!4v1720788882197!5m2!1sen!2sin"
                        //   width={600}
                        height={450}
                        style={{ border: 0, width: "100%" }}
                        allowFullScreen=""
                        loading="lazy"
                        referrerPolicy="no-referrer-when-downgrade"
                    />
                </div>
            </section>
            <Footer />
        </>
    )
}

export default ContactUs