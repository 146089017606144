import React, { useLayoutEffect, useState } from 'react';
import Breadcrumbs from '../Components/Breadcrumbs';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import GalleryData from '../Assert/GalleryData';
import Footer from '../Components/Footer';
import MakeARequest from "../Components/AboutUs/MakeARequest";
import "../Css/Gallery.css";
import { Image } from 'antd';
import { MetaTags } from 'react-meta-tags';


const Gallery = () => {

    const [items, setItems] = useState(GalleryData);
    useLayoutEffect(() => {
        window.scrollTo(0, 0)
    })

    const filterItems = (category) => {
        console.log(category)
        if (category === 'All') {
            setItems(GalleryData);
        } else {
            const updatedItems = GalleryData.filter((item) => item.category === category);
            setItems(updatedItems);
        }
    };
    console.log(items)
    return (
        <>
            <MetaTags>
                <title>Pure Veg. Caterer Near Panvel | Sree Sadguru</title>
                <meta title="Pure Veg. Caterer Near Panvel | Sree Sadguru" />
                <meta
                    name="description"
                    content="Discover Pure Veg. Caterer Near Panvel at Sree Sadguru, offering exquisite vegetarian cuisine for your special occasions."
                />
                <meta
                    name="Top Catering Services In Navi Mumbai,Best Food Catering In Navi Mumbai,Event Management In Navi Mumbai ,Best Event Planner In Navi Mumbai,Best Caterer For Wedding In Panvel, Corporate Event Planner In Navi Mumbai,Pure Veg. Caterer Near Panvel,Event Organiser In Panvel"
                />
                <link rel="canonical" href="https://www.sadgurucaterers.com/pure-veg-caterer-panvel" />
                <meta http-equiv="cache-control" content="no-cache" />
                <meta http-equiv="expires" content="0" />
                <meta http-equiv="pragma" content="no-cache" />
                <meta property="og:title" content="Pure Veg. Caterer Near Panvel | Sree Sadguru" />
                <meta property="og:locale" content="en_US" />
                <meta property="og:type" content="website" />
                <meta property="og:url" content="https://www.sadgurucaterers.com/pure-veg-caterer-panvel" />
                <meta property="og:description" content="Discover Pure Veg. Caterer Near Panvel at Sree Sadguru, offering exquisite vegetarian cuisine for your special occasions." />
                <meta property="og:image" content="https://www.sadgurucaterers.com//static/media/JMS.7493f202fc387288d7c0.png" />
            </MetaTags>
            <Breadcrumbs title="Pure Veg Caterer Near Panvel" page="Gallery" />
            <>
                <div className="homeScroll4">
                    <div className="homeSection" id="home">
                        <div className="homeText">
                            <h1 style={{ color: "#fff" }}>Gallery</h1>
                        </div>
                    </div>
                </div>
                <div className="container">
                    <section className="myGallery">
                        <div className="titleSection">
                            <h3>Browse our Gallery</h3>
                        </div>
                        <p className='text-center'>
                            Sree Sadguru Gallery showcases the culinary artistry and elegance of our Pure Veg. Caterer Near Panvel services. From vibrant wedding feasts to corporate luncheons, our gallery captures the essence of delightful dishes meticulously crafted to perfection. Explore images that highlight our commitment to quality and presentation, reflecting our passion for creating memorable dining experiences. Whether it's the intricate detailing of our desserts or the colourful array of traditional delicacies, each picture tells a story of flavour and finesse. Discover why Sree Sadguru is the preferred choice for those seeking an exceptional Pure Veg. Caterer Near Panvel.
                        </p>
                        <div className="filters filter-button-group">
                            <ul>
                                <li className="active">
                                    <a href="#all" onClick={() => filterItems('All')}>All</a>
                                </li>
                                <li>
                                    <a href="#room" onClick={() => filterItems('Events')}>Event</a>
                                </li>
                                <li>
                                    <a href="#swimmingpool" onClick={() => filterItems('Venues')}>Venues</a>
                                </li>
                                <li>
                                    <a href="#events" onClick={() => filterItems('Catering')}>Catering</a>
                                </li>
                                {/* <li>
                                    <a href="#activities" onClick={() => filterItems('activity')}>Activities</a>
                                </li> */}
                            </ul>
                        </div>
                        <Box>
                            <ImageList variant="masonry" cols={3} gap={10}>
                                {items.map((item) => (
                                    <ImageListItem key={item.img}>
                                        <Image
                                            srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                                            src={`${item.img}?w=248&fit=crop&auto=format`}

                                            loading="lazy"
                                        />
                                    </ImageListItem>

                                ))}
                            </ImageList>
                        </Box>
                    </section>
                </div>
                {/* <ImageListItem key={item.img}>
                                        <Image
                                            srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                                            src={`${item.img}?w=248&fit=crop&auto=format`}
                                            alt=""
                                            loading="lazy"
                                        />
                                    </ImageListItem> */}
            </>
            {/* <section>
                <div className="titleSection">
                    <h3>Browse our Gallery</h3>
                </div>
                <div className="filters filter-button-group">
                    <ul>
                        <li className="active">
                            <a href="#all" onClick={() => filterItems('All')}>All</a>
                        </li>
                        <li>
                            <a href="#room" onClick={() => filterItems('room')}>Room</a>
                        </li>
                        <li>
                            <a href="#swimmingpool" onClick={() => filterItems('Swimmingpool')}>Swimming Pool</a>
                        </li>
                        <li>
                            <a href="#events" onClick={() => filterItems('events')}>Events</a>
                        </li>
                        <li>
                            <a href="#activities" onClick={() => filterItems('activity')}>Activities</a>
                        </li>
                    </ul>
                </div>

                <div className='container'>
                    <h4 className='gallery-title'> Browse our library of event designs</h4>
                    <Box>
                        <ImageList variant="masonry" cols={4} gap={8}>
                            {GalleryData.map((item) => (
                                <ImageListItem key={item.img}>
                                    <img
                                        srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                                        src={`${item.img}?w=248&fit=crop&auto=format`}
                                        alt=""
                                        loading="lazy"
                                    />
                                </ImageListItem>
                            ))}
                        </ImageList>
                    </Box>
                </div>
            </section> */}
            {/* <MakeARequest /> */}
            <MakeARequest />

            <Footer />

        </>
    );
};

export default Gallery;
