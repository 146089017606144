import React from 'react'
import HomeSwiper from '../Components/HomeSwiper'
import Welcome from '../Components/Welcome'
import HomeAboutUs from '../Components/HomeAboutUs'
import WhyChooseUs from '../Components/WhyChooseUs'
import Testimonials from '../Components/Testimonials'
import Footer from '../Components/Footer'
import HomeGallery from '../Components/HomeGallery'
import MetaTags from 'react-meta-tags';


const Home = () => {



  return (
    <>
      <MetaTags>
        <title>Top Catering Services In Navi Mumbai | Sree Sadguru</title>
        <meta title="Top Catering Services In Navi Mumbai | Sree Sadguru" />
        <meta
          name="description"
          content="Sree Sadguru offers top catering services in Navi Mumbai, delivering exquisite cuisine and exceptional service for all your events."
        />
        <meta
          name="Top Catering Services In Navi Mumbai,Best Food Catering In Navi Mumbai,Event Management In Navi Mumbai ,Best Event Planner In Navi Mumbai,Best Caterer For Wedding In Panvel, Corporate Event Planner In Navi Mumbai,Pure Veg. Caterer Near Panvel,Event Organiser In Panvel"
        />
        <link rel="canonical" href="https://www.sadgurucaterers.com/" />
        <meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="expires" content="0" />
        <meta http-equiv="pragma" content="no-cache" />
        <meta property="og:title" content="Top Catering Services In Navi Mumbai | Sree Sadguru" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.sadgurucaterers.com/" />
        <meta property="og:description" content="Sree Sadguru offers top catering services in Navi Mumbai, delivering exquisite cuisine and exceptional service for all your events." />
        <meta property="og:image" content="https://www.sadgurucaterers.com//static/media/JMS.7493f202fc387288d7c0.png" />
      </MetaTags>
      <HomeSwiper />
      <Welcome />
      <HomeAboutUs />
      <WhyChooseUs />
      <Testimonials />
      <HomeGallery />
      <Footer />
    </>
  )
}

export default Home