import React, { useRef, useState } from 'react';
// Import Swiper React components
import { Swiper, SwiperSlide } from 'swiper/react';

// Import Swiper styles
import 'swiper/css';
import 'swiper/css/navigation';
import { Navigation } from 'swiper/modules';
import "../Css/Testimonials.css";
import { SiGooglehangouts } from 'react-icons/si';
import { Divider } from 'antd';

const Testimonials = () => {
    return (
        <>
            <section className='home-testimonial'>
                <div className='home-testimonial-div'>
                    <div className='container text-center home-about-us-container'>
                        <div>
                            <SiGooglehangouts className='hangouts-icon' />
                        </div>

                        <div>
                            <Swiper navigation={true} modules={[Navigation]} className="mySwiper">
                                <SwiperSlide>
                                    <div className='testimonial-para'>
                                        <p >
                                            It's was a good experience, assistance of staff was worth appreciating. Management is accomodating to your needs and are very helpful .
                                            Thank you for making our special day amazing.

                                        </p>
                                        <Divider />
                                        <p className='customer-name'>
                                            Akshay Mankame
                                        </p>
                                    </div>

                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className='testimonial-para'>
                                        <p >
                                            Excellent food quality, Nice Management may whatever the function be everything is well organised .... Will Always be my first choice for any functions.
                                        </p>
                                        <Divider />
                                        <p className='customer-name'>

                                            Gayatri Ambardekar
                                        </p>
                                    </div>

                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className='testimonial-para'>
                                        <p >
                                            Nice venue - excellent food & hospitality.
                                        </p>
                                        <Divider />
                                        <p className='customer-name'>
                                            Lion Satyapal Chugh
                                        </p>
                                    </div>

                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className='testimonial-para'>
                                        <p >
                                            Very Helpful and catered very tasty food for my daughter's Birthday.
                                        </p>
                                        <Divider />
                                        <p className='customer-name'>

                                            Srilekha Surabhi
                                        </p>
                                    </div>

                                </SwiperSlide>
                                <SwiperSlide>
                                    <div className='testimonial-para'>
                                        <p >
                                            Good service and good taste of food quality
                                        </p>
                                        <Divider />
                                        <p className='customer-name'>
                                            apoorv sharma
                                        </p>
                                    </div>

                                </SwiperSlide>
                            </Swiper>
                        </div>

                    </div>
                </div>
            </section>
        </>
    )
}

export default Testimonials