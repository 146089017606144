import React from 'react'
import Breadcrumbs from '../Components/Breadcrumbs'
import WelcomeAboutUs from '../Components/AboutUs/WelcomeAboutUs'
import OurStory from '../Components/AboutUs/OurStory'
import MakeARequest from '../Components/AboutUs/MakeARequest'
import Footer from '../Components/Footer'
import { MetaTags } from 'react-meta-tags'

const AboutUs = () => {

  return (
    <>
      <MetaTags>
        <title>Best Food Catering In Navi Mumbai | Sree Sadguru</title>
        <meta title="Best Food Catering In Navi Mumbai | Sree Sadguru" />
        <meta
          name="description"
          content="Discover the best food catering in Navi Mumbai with Sree Sadguru, offering exquisite flavors and impeccable service for every occasion."
        />
        <meta
          name="Top Catering Services In Navi Mumbai,Best Food Catering In Navi Mumbai,Event Management In Navi Mumbai ,Best Event Planner In Navi Mumbai,Best Caterer For Wedding In Panvel, Corporate Event Planner In Navi Mumbai,Pure Veg. Caterer Near Panvel,Event Organiser In Panvel"
        />
        <link rel="canonical" href="https://www.sadgurucaterers.com/best-food-catering-navi-mumbai" />
        <meta http-equiv="cache-control" content="no-cache" />
        <meta http-equiv="expires" content="0" />
        <meta http-equiv="pragma" content="no-cache" />
        <meta property="og:title" content="Best Food Catering In Navi Mumbai | Sree Sadguru" />
        <meta property="og:locale" content="en_US" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://www.sadgurucaterers.com/best-food-catering-navi-mumbai" />
        <meta property="og:description" content="Discover the best food catering in Navi Mumbai with Sree Sadguru, offering exquisite flavors and impeccable service for every occasion." />
        <meta property="og:image" content="https://www.sadgurucaterers.com//static/media/JMS.7493f202fc387288d7c0.png" />
      </MetaTags>
      <Breadcrumbs title="Best Food Catering In Navi Mumbai | Sree Sadguru" page="About us" />
      <WelcomeAboutUs />
      <OurStory />
      <MakeARequest />
      <Footer />
    </>
  )
}

export default AboutUs