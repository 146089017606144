import React, { useLayoutEffect } from 'react'
import "../Css/Footer.css";
import { Link } from "react-router-dom";
import logo from "../Assert/logo.png";
import { FaFacebook, FaInstagramSquare, FaWhatsapp } from 'react-icons/fa';
// import Logo from "../images/Green Farm.png";
const Footer = () => {
  useLayoutEffect(() => {
    window.scrollTo(0, 0);
  });
  return (
    <div>
      <footer className="footer-section">
        <div className="container">
          <div className="footer-cta pt-5 pb-5">
            <div className="row">
              <div className="col-lg-5 col-md-6  mb-30">
                <div className="single-cta">
                  <i className="fa fa-map-marker" />
                  <div className="cta-text pt-2">
                    <h4>Find us</h4>
                    <span>
                      001, Yashoraj Apt, Lokmanya Tilak Road, Manek Nagar, Old Panvel,
                      {/* In addition to our renowned food catering services, our comprehensive service portfolio offers solutions to meet all our clients' event needs. We cater to both large and small personal and corporate events. */}
                    </span>
                  </div>
                </div>
              </div>
              <div className="col-lg-4 col-md-6 mb-30">
                <div className="single-cta">
                  <i className="fa fa-phone" />
                  <div className="cta-text pt-2">
                    <h4>Call us</h4>
                    <span> 9323674158 / 9323674989 </span>
                  </div>
                </div>
              </div>
              <div className="col-lg-3 col-12 mb-30">
                <div className="single-cta">
                  <i className="fa fa-envelope-open" />
                  <div className="cta-text pt-2">
                    <h4>Mail us</h4>
                    <span>sales@sadgurucatererers.com
                    {/* <br />
                      qwerty@gmail.com */}
                      </span>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div className="footer-content pt-5 pb-5">
            <div className="row">
              <div className="col-xl-4 col-lg-4 mb-50">
                <div className="footer-widget">
                  <div className="footer-logo">
                    <a href="index.html">
                      <img
                        src={logo}
                        className="img"
                        alt="logo"
                      />
                    </a>
                    {/* <span className="d-block">
                      In addition to our renowned food catering services, our comprehensive service portfolio offers solutions to meet all our clients' event needs. We cater to both large and small personal and corporate events.
                    </span> */}
                  </div>
                  <div className="footer-text">
                    {/* <p>
                      Lorem ipsum is a placeholder text commonly
                    </p> */}
                  </div>
                  <div className="footer-widget">
                    {/* <div className="footer-widget-heading">
              <h3></h3>
            </div> */}


                  </div>

                </div>
              </div>
              <div className="col-xl-4 col-lg-4 col-md-6 mb-30">
                <div className="footer-widget">
                  <div className="footer-widget-heading">
                    <h3>Useful Links</h3>
                  </div>
                  <ul className="m-0 p-0">
                    <li>
                      <Link to="/">Home</Link>
                    </li>



                    <li>
                      <Link to="/best-food-catering-navi-mumbai">About Us</Link>
                    </li>

                    <li>
                      <Link to="/event-management-navi-mumbai">Events</Link>
                    </li>
                    <li>
                      <Link to="/pure-veg-caterer-panvel">Gallery</Link>
                    </li>
                    <li>
                      <Link to="/event-organiser-panvel">Contact Us </Link>
                    </li>
                  </ul>
                </div>

              </div>
              <div className="col-xl-4 col-lg-4 col-md-6 mb-50">
                <div className="footer-widget">
                  <div className="footer-widget-heading">
                    <h3>Address</h3>
                  </div>
                  <div className="footer-text mb-25">
                    <p>
                      001, Yashoraj Apt, Lokmanya Tilak Road, Manek Nagar, Old Panvel, Panvel, Navi Mumbai, Maharashtra 410206                    </p>
                  </div>
                  {/* <div className="footer-social-icon mt-lg-5">
              <span>Follow us</span>
              <a href="#">
                <i className="fa fa-facebook-f facebook-bg" />
              </a>
              <a href="#">
                <i className="fa fa-twitter twitter-bg" />
              </a>
              <a href="#">
                <i className="fab fa-google-plus-g google-bg" />
              </a>
            </div> */}
                </div>

              </div>
            </div>
          </div>
        </div>
        <div className="copyright-area">
          <div className="container">
            <div className="row">
              <div className="col-xl-6 col-lg-6 text-center text-lg-left">
                <div className="copyright-text">
                  <p>
                    Copyright © 2024, All Right Reserved{" "}
                    <br />
                    <a href="https://www.sadgurucaterers.com/">Sadguru Caterers</a>
                  </p>
                  <p>
                    Developed By &nbsp;
                    <a href="https://skdm.in/">Shree Krishna Digital Marketing</a>
                  </p>
                </div>
              </div>

            </div>
          </div>
        </div>


      </footer>

      <div className="floattingButton">
        {/* <a href="#" className="float" id="menu-share">
    <i className="fa fa-link my-float" />
  </a> */}
        <ul>
          <li>
            <a href="https://wa.me/9323674989" target='_blank'>
              <i className="fa fa-whatsapp my-float" >
                <FaWhatsapp />

              </i>
            </a>
          </li>
          {/* <li>
            <a href="https://www.instagram.com/" target='_blank'>
              <i className="fa fa-instagram my-float" >
                <FaInstagramSquare />

              </i>

            </a>
          </li>
          <li>
            <a href="https://facebook.com/" target='_blank'>
              <i className=" my-float" >
                <FaFacebook />

              </i>
            </a>
          </li> */}
        </ul>
      </div>
    </div>
  )
}

export default Footer